import { useState } from "react";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";

function FAQs() {
  const [selected1, setSelected1] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const [selected3, setSelected3] = useState(null);
  const [selected4, setSelected4] = useState(null);
  const [selected5, setSelected5] = useState(null);
  const [selected6, setSelected6] = useState(null);
  const [selected7, setSelected7] = useState(null);
  const [selected8, setSelected8] = useState(null);

  const toggleFAQ1 = (index) => {
    if (selected1 === index) {
      return setSelected1(null);
    }
    setSelected1(index);
  };
  const toggleFAQ2 = (index) => {
    if (selected2 === index) {
      return setSelected2(null);
    }
    setSelected2(index);
  };
  const toggleFAQ3 = (index) => {
    if (selected3 === index) {
      return setSelected3(null);
    }
    setSelected3(index);
  };
  const toggleFAQ4 = (index) => {
    if (selected4 === index) {
      return setSelected4(null);
    }
    setSelected4(index);
  };
  const toggleFAQ5 = (index) => {
    if (selected5 === index) {
      return setSelected5(null);
    }
    setSelected5(index);
  };
  const toggleFAQ6 = (index) => {
    if (selected6 === index) {
      return setSelected6(null);
    }
    setSelected6(index);
  };
  const toggleFAQ7 = (index) => {
    if (selected7 === index) {
      return setSelected7(null);
    }
    setSelected7(index);
  };
  const toggleFAQ8 = (index) => {
    if (selected8 === index) {
      return setSelected8(null);
    }
    setSelected8(index);
  };
  const faqs1 = [
    {
      question: "What is Promarvel?",
      answer:
        "Promarvel is an online home services provider based in Hyderabad, offering personal grooming, appliance repair, pest control, cleaning services, plumbing, and carpentry services.",
    },
    {
      question: "Where does Promarvel operate?",
      answer: "Promarvel operates exclusively in Hyderabad.",
    },
    {
      question: "How can I book a service?",
      answer:
        "Services can only be booked through the Promarvel app, available on Android and iOS.",
    },
    {
      question: "Can I visit a Promarvel store?",
      answer:
        "Yes, you can visit a Promarvel store for salon services by selecting the “ Visit a Promarvel Store” option in the app. Walk-ins are also accepted, but preference is given to app bookings.",
    },
    {
      question: "Can I cancel or reschedule my booking?",
      answer:
        "Yes, cancellations or reschedules must be made at least 2 hours before the scheduled time.",
    },
    {
      question: "Can I book services for the same day?",
      answer:
        "Yes, Promarvel allows same-day service requests through the Rapid Request feature, subject to availability.",
    },
    {
      question: "Are there any recurring service options?",
      answer:
        "Yes, with the Schedule & Save option, you can book recurring services such as cleaning or pest control at discounted rates.",
    },
  ];
  const faqs2 = [
    {
      question: "What are Promarvel’s support hours?",
      answer:
        "Our customer support team is available from 9:00 AM to 6:00 PM daily.",
    },
    {
      question: "How can I contact customer support?",
      answer:
        "You can reach support through the Help section in the app, call the helpline, or email us directly.",
    },
    {
      question: " What should I do if I face an issue with a service?",
      answer:
        "You can report any issue through the app or contact our support team for immediate assistance.",
    },
  ];
  const faqs3 = [
    {
      question:
        "Who are the professionals providing services through Promarvel?",
      answer:
        "Promarvel works with trained, background-verified professionals to ensure high-quality and safe service delivery.",
    },
    {
      question: "How are professionals trained?",
      answer:
        "All professionals undergo intensive training and quality checks before joining the platform to ensure customer satisfaction.",
    },
    {
      question: " Can I rate or review a professional?",
      answer:
        "Yes, after a service is completed, you can rate and review the professional through the app.",
    },
    {
      question: " What happens if I am unsatisfied with a professional’s work?",
      answer:
        "You can file a complaint through the app, and our team will take the necessary steps, including rebooking or refunding, if applicable.",
    },
    {
      question:
        "Are professionals equipped with the necessary tools and materials?",
      answer:
        "Yes, all professionals bring the required tools and high-quality materials needed for the service.",
    },
  ];
  const faqs4 = [
    {
      question: " What services does Promarvel offer?",
      answer:
        "Promarvel provides a wide range of services, including: 1.Beauty & Grooming: Haircuts, facials, SPA, 2.Home Repairs: Plumbing, carpentry, electronics repair,3.Cleaning Services: Deep cleaning, pest control",
    },
    {
      question: "Can I book a service outside Hyderabad?",
      answer: "Currently, Promarvel services are available only in Hyderabad.",
    },
    {
      question: "What happens if a professional doesn’t show up?",
      answer:
        "You can contact support for immediate resolution or reschedule the service free of charge. ",
    },
  ];
  const faqs5 = [
    {
      question: "What payment methods does Promarvel accept?",
      answer:
        "We accept online payments via UPI, credit/debit cards, and wallets through the app.",
    },
    {
      question: "Are there any additional charges?",
      answer:
        "All service charges are transparent and visible during booking. Additional charges may apply for extra services requested on-site.",
    },
    {
      question: "What is the refund policy?",
      answer:
        "Refunds are processed within 3–5 business days for eligible cancellations. ",
    },
  ];
  const faqs6 = [
    {
      question: "Which services come with a warranty?",
      answer:
        "A 1-month unconditional warranty is provided for appliance repairs, pest control, and carpentry, excluding physical damage.",
    },
    {
      question: "How do I claim a warranty?",
      answer:
        "Claims can be made through the Warranty section in your profile on the app.",
    },
    {
      question: "Can I rebook under warranty?",
      answer:
        "Yes, you can rebook the service for free if issues persist during the warranty period. ",
    },
    {
      question: "Are there exclusions to the warranty?",
      answer:
        "The warranty does not cover issues caused by physical damage or misuse. ",
    },
  ];
  const faqs7 = [
    {
      question: "Can I schedule multiple services in one booking?",
      answer:
        "Yes, you can schedule multiple services for the same day or spread them across different dates through the app.",
    },
    {
      question: "How are service slots confirmed?",
      answer:
        "Service slots are confirmed instantly upon successful booking through the app.",
    },
    {
      question: "Can I refer friends to Promarvel?",
      answer:
        "Yes, Promarvel offers a referral program where you and your friends can earn discounts on bookings. ",
    },
    {
      question: "Does Promarvel provide insurance for damages?",
      answer:
        "While no damage is expected, Promarvel ensures professionals are trained to minimize risks. However, there is no explicit insurance policy for damages. ",
    },
  ];
  const faqs8 = [
    {
      question: "Who can become a Promarvel professional?",
      answer:
        "Promarvel welcomes skilled individuals in areas such as beauty, grooming, home repairs, cleaning, and electronics to join our platform.",
    },
    {
      question: "How do I register as a professional?",
      answer:
        "You can register as a professional through the Promarvel Partner App or the Promarvel website. Register via App: Download the Promarvel Partner App from the Google Play Store or App Store, Sign up by providing your basic details, including your name, phone number, and area of expertise,Complete the profile setup by uploading required documents like ID proof, address proof and certifications, Submit the application. Our team will review it and contact you for verification. ,Register via Website:Visit the Promarvel website, Navigate to the Register as a Professional section, Fill out the online form with your personal details, skills, and upload the required documents, Submit the form. A Promarvel representative will reach out to guide you through the next steps.",
    },
    {
      question: "Is there a registration fee?",
      answer:
        "No, registering as a professional on Promarvel is free of charge.",
    },
    {
      question: "What documents are required for registration?",
      answer:
        "You will need the following: Government-issued ID (Aadhar, PAN, etc.), Address proof, Bank account details for payments, Skill certification (if applicable)",
    },
    {
      question: "How do I download the Promarvel Partner App?",
      answer:
        "Visit the Google Play Store or App Store, Search for Promarvel Partner App, Click Install and follow the on-screen instructions.",
    },
    {
      question: "How do professionals get paid?",
      answer:
        "Payments are transferred directly to the bank account linked to your profile. Settlements are processed weekly or based on completed bookings.",
    },
    {
      question: "What training does Promarvel provide?",
      answer:
        "Promarvel offers training to ensure professionals meet our service quality standards. Training covers technical skills, customer service, and safety protocols.",
    },
    {
      question: "What support is available for professionals?",
      answer:
        "Promarvel offers 24/7 support for professionals through the Partner App and website. Support includes: Assistance with bookings, Help resolving disputes, Guidance for improving service quality.",
    },
    {
      question: "Can I choose my working hours?",
      answer:
        "Yes, professionals can set their availability through the app, allowing flexibility to work at preferred times.",
    },
    {
      question: "What happens if a customer cancels a booking?",
      answer:
        "If a booking is canceled within the cancellation window, no penalty is applied. Outside this window, Promarvel’s policy ensures fair compensation for professionals.",
    },
  ];

  return (
    <>
      <div className="bg-[#1a1a1a] text-white">
        <div className="containers py-10">
          <section>
            <div className="containers sm1:py-20 py-10">
              <h2 className="text-white fonts-500 2xl:text-4xl xl:text-3xl lg:text-2xl text-xl xl:leading-[67px] leading-10 text-center underline">
                USER
              </h2>
              <div>
                <h3 className="2xl:text-3xl xl:text-2xl lg:text-xl text-lg font-medium underline pt-10 pb-14">
                  General FAQs
                </h3>
                <div className=" mx-auto  pb-6 text-white">
                  <div className="xl:space-y-6 sm:space-y-8 space-y-6">
                    {faqs1.map((faq, index) => (
                      <div key={index}>
                        <button
                          className="w-full text-left focus:outline-none flex justify-between items-start"
                          onClick={() => toggleFAQ1(index)}
                        >
                          <span className=" xl:text-xl md:text-lg sm:text-base text-sm xl:pb-3 sm:pb-2 pb-1 fonts-500 xl:leading-8 md:leading-7 sm:leading-6 leading-5">
                            {faq.question}
                          </span>
                          <span>
                            {selected1 === index ? (
                              <IoIosArrowDropupCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            ) : (
                              <IoIosArrowDropdownCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            )}
                          </span>
                        </button>
                        {selected1 === index && (
                          <div className="fonts-400 4xl:text-lg xl:text-base sm:text-sm text-xs text-[#c9c9c9] xl:leading-[48px] sm:leading-9 leading-7 w-auto">
                            {faq.answer}
                          </div>
                        )}
                        <div className="border-b border-[#292929] xl:pt-5 sm:pt-4 pt-4"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="2xl:text-3xl xl:text-2xl lg:text-xl text-lg font-medium underline pt-10 pb-14">
                  Support FAQs
                </h3>
                <div className=" mx-auto  pb-6 text-white">
                  <div className="xl:space-y-6 sm:space-y-8 space-y-6">
                    {faqs2.map((faq, index) => (
                      <div key={index}>
                        <button
                          className="w-full text-left focus:outline-none flex justify-between items-start"
                          onClick={() => toggleFAQ2(index)}
                        >
                          <span className=" xl:text-xl md:text-lg sm:text-base text-sm xl:pb-3 sm:pb-2 pb-1 fonts-500 xl:leading-8 md:leading-7 sm:leading-6 leading-5">
                            {faq.question}
                          </span>
                          <span>
                            {selected2 === index ? (
                              <IoIosArrowDropupCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            ) : (
                              <IoIosArrowDropdownCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            )}
                          </span>
                        </button>
                        {selected2 === index && (
                          <div className="fonts-400 4xl:text-lg xl:text-base sm:text-sm text-xs text-[#c9c9c9] xl:leading-[48px] sm:leading-9 leading-7 w-auto">
                            {faq.answer}
                          </div>
                        )}
                        <div className="border-b border-[#292929] xl:pt-5 sm:pt-4 pt-4"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="2xl:text-3xl xl:text-2xl lg:text-xl text-lg font-medium underline pt-10 pb-14">
                  Professional FAQs
                </h3>
                <div className=" mx-auto  pb-6 text-white">
                  <div className="xl:space-y-6 sm:space-y-8 space-y-6">
                    {faqs3.map((faq, index) => (
                      <div key={index}>
                        <button
                          className="w-full text-left focus:outline-none flex justify-between items-start"
                          onClick={() => toggleFAQ3(index)}
                        >
                          <span className=" xl:text-xl md:text-lg sm:text-base text-sm xl:pb-3 sm:pb-2 pb-1 fonts-500 xl:leading-8 md:leading-7 sm:leading-6 leading-5">
                            {faq.question}
                          </span>
                          <span>
                            {selected3 === index ? (
                              <IoIosArrowDropupCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            ) : (
                              <IoIosArrowDropdownCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            )}
                          </span>
                        </button>
                        {selected3 === index && (
                          <div className="fonts-400 4xl:text-lg xl:text-base sm:text-sm text-xs text-[#c9c9c9] xl:leading-[48px] sm:leading-9 leading-7 w-auto">
                            {faq.answer}
                          </div>
                        )}
                        <div className="border-b border-[#292929] xl:pt-5 sm:pt-4 pt-4"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="2xl:text-3xl xl:text-2xl lg:text-xl text-lg  font-medium underline pt-10 pb-14">
                  Services FAQs
                </h3>
                <div className=" mx-auto  pb-6 text-white">
                  <div className="xl:space-y-6 sm:space-y-8 space-y-6">
                    {faqs4.map((faq, index) => (
                      <div key={index}>
                        <button
                          className="w-full text-left focus:outline-none flex justify-between items-start"
                          onClick={() => toggleFAQ4(index)}
                        >
                          <span className=" xl:text-xl md:text-lg sm:text-base text-sm xl:pb-3 sm:pb-2 pb-1 fonts-500 xl:leading-8 md:leading-7 sm:leading-6 leading-5">
                            {faq.question}
                          </span>
                          <span>
                            {selected4 === index ? (
                              <IoIosArrowDropupCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            ) : (
                              <IoIosArrowDropdownCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            )}
                          </span>
                        </button>
                        {selected4 === index && (
                          <div className="fonts-400 4xl:text-lg xl:text-base sm:text-sm text-xs text-[#c9c9c9] xl:leading-[48px] sm:leading-9 leading-7 w-auto">
                            {faq.answer}
                          </div>
                        )}
                        <div className="border-b border-[#292929] xl:pt-5 sm:pt-4 pt-4"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="2xl:text-3xl xl:text-2xl lg:text-xl text-lg font-medium underline pt-10 pb-14">
                  Payments FAQs
                </h3>
                <div className=" mx-auto  pb-6 text-white">
                  <div className="xl:space-y-6 sm:space-y-8 space-y-6">
                    {faqs5.map((faq, index) => (
                      <div key={index}>
                        <button
                          className="w-full text-left focus:outline-none flex justify-between items-start"
                          onClick={() => toggleFAQ5(index)}
                        >
                          <span className=" xl:text-xl md:text-lg sm:text-base text-sm xl:pb-3 sm:pb-2 pb-1 fonts-500 xl:leading-8 md:leading-7 sm:leading-6 leading-5">
                            {faq.question}
                          </span>
                          <span>
                            {selected5 === index ? (
                              <IoIosArrowDropupCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            ) : (
                              <IoIosArrowDropdownCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            )}
                          </span>
                        </button>
                        {selected5 === index && (
                          <div className="fonts-400 4xl:text-lg xl:text-base sm:text-sm text-xs text-[#c9c9c9] xl:leading-[48px] sm:leading-9 leading-7 w-auto">
                            {faq.answer}
                          </div>
                        )}
                        <div className="border-b border-[#292929] xl:pt-5 sm:pt-4 pt-4"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="2xl:text-3xl xl:text-2xl lg:text-xl text-lg font-medium underline pt-10 pb-14">
                  Warranty FAQs
                </h3>
                <div className=" mx-auto  pb-6 text-white">
                  <div className="xl:space-y-6 sm:space-y-8 space-y-6">
                    {faqs6.map((faq, index) => (
                      <div key={index}>
                        <button
                          className="w-full text-left focus:outline-none flex justify-between items-start"
                          onClick={() => toggleFAQ6(index)}
                        >
                          <span className=" xl:text-xl md:text-lg sm:text-base text-sm xl:pb-3 sm:pb-2 pb-1 fonts-500 xl:leading-8 md:leading-7 sm:leading-6 leading-5">
                            {faq.question}
                          </span>
                          <span>
                            {selected6 === index ? (
                              <IoIosArrowDropupCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            ) : (
                              <IoIosArrowDropdownCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            )}
                          </span>
                        </button>
                        {selected6 === index && (
                          <div className="fonts-400 4xl:text-lg xl:text-base sm:text-sm text-xs text-[#c9c9c9] xl:leading-[48px] sm:leading-9 leading-7 w-auto">
                            {faq.answer}
                          </div>
                        )}
                        <div className="border-b border-[#292929] xl:pt-5 sm:pt-4 pt-4"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <h3 className="2xl:text-3xl xl:text-2xl lg:text-xl text-lg font-medium underline pt-10 pb-14">
                  Additional FAQs
                </h3>
                <div className=" mx-auto  pb-6 text-white">
                  <div className="xl:space-y-6 sm:space-y-8 space-y-6">
                    {faqs7.map((faq, index) => (
                      <div key={index}>
                        <button
                          className="w-full text-left focus:outline-none flex justify-between items-start"
                          onClick={() => toggleFAQ7(index)}
                        >
                          <span className=" xl:text-xl md:text-lg sm:text-base text-sm xl:pb-3 sm:pb-2 pb-1 fonts-500 xl:leading-8 md:leading-7 sm:leading-6 leading-5">
                            {faq.question}
                          </span>
                          <span>
                            {selected7 === index ? (
                              <IoIosArrowDropupCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            ) : (
                              <IoIosArrowDropdownCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            )}
                          </span>
                        </button>
                        {selected7 === index && (
                          <div className="fonts-400 4xl:text-lg xl:text-base sm:text-sm text-xs text-[#c9c9c9] xl:leading-[48px] sm:leading-9 leading-7 w-auto">
                            {faq.answer}
                          </div>
                        )}
                        <div className="border-b border-[#292929] xl:pt-5 sm:pt-4 pt-4"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <h2 className="text-white fonts-500 2xl:text-4xl xl:text-3xl lg:text-2xl sm1:text-xl text-xl xl:leading-[67px] leading-10 text-center underline pt-14">
                PROVIDER
              </h2>
              <div>
                <h3 className="2xl:text-3xl xl:text-2xl lg:text-xl text-lg font-medium underline pt-10 pb-14">
                  Professionals FAQs
                </h3>
                <div className=" mx-auto  pb-6 text-white">
                  <div className="xl:space-y-6 sm:space-y-8 space-y-6">
                    {faqs8.map((faq, index) => (
                      <div key={index}>
                        <button
                          className="w-full text-left focus:outline-none flex justify-between items-start"
                          onClick={() => toggleFAQ8(index)}
                        >
                          <span className=" xl:text-xl md:text-lg sm:text-base text-sm xl:pb-3 sm:pb-2 pb-1 fonts-500 xl:leading-8 md:leading-7 sm:leading-6 leading-5">
                            {faq.question}
                          </span>
                          <span>
                            {selected8 === index ? (
                              <IoIosArrowDropupCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            ) : (
                              <IoIosArrowDropdownCircle className="4xl:text-3xl xl:text-2xl md:text-xl sm:text-lg text-base" />
                            )}
                          </span>
                        </button>
                        {selected8 === index && (
                          <div className="fonts-400 4xl:text-lg xl:text-base sm:text-sm text-xs text-[#c9c9c9] xl:leading-[48px] sm:leading-9 leading-7 w-auto">
                            {faq.answer}
                          </div>
                        )}
                        <div className="border-b border-[#292929] xl:pt-5 sm:pt-4 pt-4"></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
export default FAQs;
