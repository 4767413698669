import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../App.css";
import arrow from "../../Assets/images/bottomtotopicon.png";

function ScrollToTopButton() {
  const [visible, setVisible] = useState(false);
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  useEffect(() => {
    const handleScroll = () => {
      setVisible(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <img
        src={arrow}
        onClick={scrollToTop}
        className="lg:w-12 lg:h-12 md:w-10 md:h-10 w-9 h-9 md:bottom-20 md1:bottom-12 bottom-16 md:right-10 right-5 z-50 fixed cursor-pointer"
        style={{
          display: visible ? "" : "none",
        }}
      />
    </>
  );
}
export default ScrollToTopButton;
