import img1 from "../../Assets/images/Ajay.jpg";
import img2 from "../../Assets/images/Gowtham.JPG";
import img3 from "../../Assets/images/Bhargavi.jpg";
import img4 from "../../Assets/images/vamsi.jpg";
import img5 from "../../Assets/images/Akhil.jpg";
import img6 from "../../Assets/images/rohith.jpg";
import img7 from "../../Assets/images/srikanth.jpg";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AboutUsPage() {
  const testimonials = [
    {
      image: img1,

      text: "Founder The visionary behind PROMARVEL, Ajay turns bold ideas into reality, creating a platform where customers and professionals thrive.",
      author: "- Ajay Nagireddy",
    },
    {
      image: img2,
      text: "Co-Founder A natural strategist, Vasuki ensures PROMARVEL stays ahead of the curve, delivering top-notch service experiences.",
      author: "-Vasuki Gowtham",
    },
    {
      image: img3,
      text: "Co-Founder With a pulse on what customers love, Bhargavi drives engagement, making sure PROMARVEL is the brand everyone trusts.",
      author: "- Gottapu Bhargavi",
    },
    {
      image: img4,
      text: "Technology Expert Vamsi crafts the tech magic that keeps PROMARVEL smooth, reliable, and always ready for action.",
      author: "- MVK Vamsi",
    },
    {
      image: img5,
      text: "Technology Expert Akhil’s expertise ensures our platform stays innovative and user-friendly, delivering the best possible experience.",
      author: "- Akhil Chapatti",
    },
    {
      image: img6,
      text: "Finance Specialist The financial brain behind PROMARVEL, Rohit keeps us on the path to sustainable growth and success.",
      author: "- Rohit Chitti ",
    },
    {
      image: img7,
      text: "Report Design Specialist With a keen eye for detail, Srikanth transforms data into powerful stories, keeping us informed and inspired.",
      author: "- Srikanth Samala",
    },
  ];

  var settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    dotsClass: "slick-dots custom-dots",
  };
  return (
    <>
      <div className="bg-[#1a1a1a] text-white">
        <div className="containers py-10">
          <div className="border sm:px-10 px-5 md:py-16 py-10 rounded-2xl ">
            <h2 className="md:text-3xl text-2xl font-bold">
              ABOUT US - PROMARVEL: Turning Ordinary into Extraordinary
            </h2>
            <div className="pt-10 space-y-5 sm1:text-base text-sm">
              <p>
                At <b>PROMARVEL</b>, we don’t just offer home services—we bring
                convenience, care, and craftsmanship right to your doorstep.
                Think of us as your personal home hero, always ready to rescue
                you from the chaos of leaky faucets, tired walls, or overdue
                grooming needs.
              </p>
              <p>
                We believe home services should be{" "}
                <b>simple, stress-free, and delightful</b>. Whether you’re
                booking a beauty service to feel refreshed or need a reliable
                pro for a quick repair, PROMARVEL connects you with skilled
                experts who love what they do. Every service is delivered with a
                touch of excellence—and a whole lot of heart.
              </p>
            </div>
            <div className="pt-8  space-y-5">
              <h2 className="text-lg font-bold ">OUR PROMISE TO YOU</h2>
              <ul className="list-disc list-outside pl-5 sm1:text-base text-sm">
                <li>
                  We’re not just here to make your home sparkle; we’re here to
                  make life easier. No endless searches, no haggling over
                  prices, just reliable services tailored to your needs. Because
                  at PROMARVEL, your comfort is our commitment.
                </li>
              </ul>
            </div>
            <div className="pt-8 space-y-5">
              <h2 className="text-lg font-bold">
                WHAT MAKES PROMARVEL DIFFERENT ?
              </h2>
              <ul className="list-disc list-outside pl-5 space-y-4 sm1:text-base text-sm">
                <li>
                  <b>Fast, Easy Booking</b>: Need help in a flash? Book in
                  seconds, sit back, and relax.
                </li>
                <li>
                  <b>Top-Notch Experts</b>: Every professional is vetted,
                  skilled, and ready to impress.
                </li>
                <li>
                  <b>Transparent Pricing</b>: No surprises, no hidden fees—just
                  clear, fair pricing.
                </li>
                <li>
                  <b>Eco-Friendly Services</b>: We care for your home and the
                  planet, promoting green practices wherever we can.
                </li>
                <li>
                  <b>Always There for You</b>: Our support team is ready to
                  help, whether you have a question or need a last-minute fix.
                </li>
              </ul>
            </div>
            <div className="pt-8 space-y-5">
              <h2 className="text-lg font-semibold">OUR MISSION</h2>
              <ul className="list-disc list-outside pl-5 sm1:text-base text-sm">
                <li>
                  {" "}
                  To make every home service feel like a luxury
                  experience—simple, seamless, and tailored to you.
                </li>
              </ul>
            </div>
            <div className="pt-8 space-y-5">
              <h2 className="text-lg font-semibold">OUR VISION</h2>
              <ul className="list-disc list-outside pl-5 sm1:text-base text-sm">
                <li>
                  To lead the home services revolution in India, one satisfied
                  customer and empowered professional at a time.
                </li>
              </ul>
            </div>
            <div className="pt-8 space-y-5">
              <h2 className="text-lg font-semibold">
                FUELING DREAMS , EMPOWERING PROFESSIONALS
              </h2>
              <p>
                We’re not just about homes—we’re about people. PROMARVEL is
                proud to support gig workers with:
              </p>
              <ul className="list-disc list-outside pl-5 space-y-4 sm1:text-base text-sm">
                <li>
                  <b>Health Insurance</b> for those who’ve been with us for 1
                  month or more.
                </li>
                <li>
                  <b>Ongoing Training</b> to help our experts sharpen their
                  skills and grow.
                </li>
                <li>
                  <b>Fair Pay</b> through our transparent commission model,
                  ensuring every professional earns what they deserve.
                </li>
              </ul>
            </div>
            <div className="pt-8 space-y-5">
              <h2 className="text-lg font-semibold">OUR VISION FOR TOMORROW</h2>
              <ul className="list-disc list-outside pl-5 sm1:text-base text-sm">
                <li>
                  Hyderabad is just the beginning. With plans to expand across
                  India, we’re bringing our passion for exceptional service to
                  new cities, new homes, and new possibilities. Expect fresh
                  features, innovative ideas, and more ways to make your life a
                  breeze.
                </li>
              </ul>
            </div>
            <section>
              <div className="text-start text-white">
                <h2 className="text-lg font-semibold pb-5 pt-10">
                  MEET THE DREAM TEAM
                </h2>
              </div>
              <div className="py-10">
                <div className="slider-container 2xl:mx-44 xl:mx-20 lg:mx-30   ">
                  <Slider {...settings2}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index}>
                        <div className="flex items-center justify-center text-white sm:px-3">
                          <div className="md:w-[800px]  text-center ">
                            <img
                              src={testimonial.image}
                              alt={`Image of ${testimonial.author}`}
                              className="sm:w-40 sm:h-40 w-32 h-32 rounded-full bg-center object-cover place-self-center sm:mb-10 mb-5"
                            />
                            <p className="lg:text-lg md:text-lg sm1:text-sm text-xs sm1:leading-5 align-center lg:leading-7 mb-4">
                              {testimonial.text}
                            </p>
                            <p className="md:text-xl sm1:text-sm text-xs fonts font-semibold sm:mb-5 mb-8">
                              {testimonial.author}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </section>
            <div className="pt-6 space-y-5 sm1:text-base text-sm">
              <p>
                <b>We Take Security Seriously</b> Your trust means everything to
                us. PROMARVEL ensures your data is protected, transactions are
                secure, and every professional is verified.
              </p>
              <p>
                <b>Logo Design Credit</b> A big shoutout to <b>Kavya</b>, known
                as Icongeek 26 on Behance, for designing our logo. Check out her
                amazing work{" "}
                <a
                  href="https://www.behance.net/kavyas"
                  target="_blank"
                  className="underline text-[#3de3b7]"
                >
                  here.
                </a>
              </p>
              <p>
                At PROMARVEL, we believe every service should be an experience
                worth talking about.
                <b>Your Home, Our Marvel—Let’s Make It Shine !</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AboutUsPage;
