import React, { useState } from "react";
import logo2 from "../../Assets/images/mainlogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { AlignJustify, X, ArrowLeft } from "lucide-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../App.css";
import "../../Assets/css/media.css";
import { TiArrowSortedDown } from "react-icons/ti";
import { SlClose } from "react-icons/sl";

import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";

const validationSchema = Yup.object({
  fname: Yup.string().required("Full Name is required"),
  phoneno: Yup.string().required("Phone number is required"),
  city: Yup.string().required("City is required"),
  profession: Yup.string().required("Profession is required"),
  experience: Yup.string().required("Experience is reqiured"),
  gender: Yup.string().required("Gender is required"),
  message: Yup.string().required("Message is required"),
});

const gender = [
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
];
const city = [
  {
    id: 1,
    name: "Hyderabad",
  },
];
const experience = [
  {
    id: 1,
    name: "1 Year",
  },
  {
    id: 2,
    name: "2 Year",
  },
  {
    id: 3,
    name: "3 Year",
  },
  {
    id: 4,
    name: "5+ Year",
  },
];
const profession = [
  {
    id: 1,
    name: "Beautician",
  },
  {
    id: 2,
    name: "Massage Therapist",
  },
  {
    id: 3,
    name: "Makeup Artist",
  },
  {
    id: 4,
    name: "Hair Stylist",
  },
];
function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      fname: "",
      phoneno: "",
      city: "",
      profession: "",
      experience: "",
      gender: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log("values>>>>>>>>", values);
      resetForm();
      setPhoneData(" ");
      setOpen(false);
    },
  });

  const handleregister = () => {
    setIsOpen(false);
    setOpen(true);
  };
  const handleclose = () => {
    setOpen(false);
    formik.resetForm();
  };
  const [phoneData, setPhoneData] = useState({
    number: "",
    countryCode: "in",
    dialCode: "91",
    inputValue: "",
    isValid: false,
  });
  const handlePhoneChange = (phone, countryCode) => {
    const userInput =
      countryCode && countryCode.country && countryCode.country.dialCode
        ? phone.replace(`+${countryCode.country.dialCode}`, "").trim()
        : phone.trim();

    const parsedPhone = parsePhoneNumberFromString(phone);

    const updatedPhoneData = {
      number: phone,
      countryCode:
        countryCode && countryCode.country ? countryCode.country.iso2 : "in",
      dialCode:
        countryCode && countryCode.country
          ? `+${countryCode.country.dialCode}`
          : "+91",
      inputValue: userInput,
      isValid: parsedPhone ? parsedPhone.isValid() : false,
    };
    setPhoneData(updatedPhoneData);
    formik.setFieldValue("phoneno", updatedPhoneData.number);
  };

  return (
    <>
      <div className="bg-[#000] text-white fonts-400 sticky top-0 z-50">
        <div className="containers">
          <div className="lg:flex hidden justify-between items-center h-[92px]">
            <div
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={logo2} className="w-[60px] h-[70px]" alt="logo" />
            </div>
            <div className="items-center flex justify-center ">
              <Link
                to="/"
                className="2xl:px-5 xl:px-3 xl:mx-[5px] lg:mx-2 py-2.5 font-medium  "
              >
                <div className="flex items-center whitespace-nowrap">
                  <p className=" xl:text-base text-sm leading-7">Home</p>
                </div>
              </Link>
              <Link
                to="/faqs"
                className="2xl:px-5 xl:px-3 xl:mx-[5px] lg:mx-2 py-2.5 font-medium "
              >
                <div className="flex items-center whitespace-nowrap">
                  <p className=" xl:text-base text-sm leading-7">FAQs</p>
                </div>
              </Link>
              <Link
                to="/#contact"
                className="2xl:px-5 xl:px-3 xl:mx-[5px] lg:mx-2 py-2.5 font-medium "
              >
                <div className="flex items-center whitespace-nowrap">
                  <p className="xl:text-base text-sm leading-7">Contact Us</p>
                </div>
              </Link>
              <Link
                to="/privacy_policy"
                className="2xl:px-5 xl:px-3 xl:mx-[5px] lg:mx-2 py-2.5 font-medium "
              >
                <div className="flex items-center whitespace-nowrap">
                  <p className=" xl:text-base text-sm leading-7">
                    Privacy Policy
                  </p>
                </div>
              </Link>
              <Link
                to="/terms_of_use"
                className="2xl:px-5 xl:px-3 xl:mx-[5px] lg:mx-2 py-2.5 font-medium "
              >
                <div className="flex items-center whitespace-nowrap">
                  <p className="xl:text-base text-sm leading-7">Terms Of Use</p>
                </div>
              </Link>
              <div className="xl:mx-[5px]  py-2.5 font-medium bg-white text-black rounded-xl">
                <div>
                  <button
                    onClick={() => setOpen(true)}
                    className="xl:text-base text-sm leading-7 2xl:px-8 px-5 "
                  >
                    Sign Up as a Professional
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full ">
            <div className="  lg:hidden h-[80px] flex justify-between items-center">
              <div>
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="border rounded-[11px] p-1.5"
                >
                  {isOpen ? (
                    <span className="text-white">
                      <X />
                    </span>
                  ) : (
                    <div>
                      <span className="text-white text-xl">
                        <AlignJustify />
                      </span>
                    </div>
                  )}
                </button>
              </div>
              <div
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={logo2} alt="logo" className="w-[55px] h-[65px]" />
              </div>
            </div>
          </div>
          {isOpen && (
            <div
              className="fixed inset-0 bg-[#0000004D] lg:hidden z-10 backdrop-blur-sm"
              onClick={() => setIsOpen(false)}
            ></div>
          )}
          <div
            className={`fixed p-9 top-0 left-0 h-full bg-[#000] z-20 transform overflow-auto sm:w-[350px] w-[70%] ${
              isOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-500 ease-in-out lg:hidden`}
          >
            <div className="flex justify-center items-center">
              <div>
                <img
                  src={logo2}
                  onClick={() => {
                    navigate("/");
                    setIsOpen(false);
                  }}
                  className=" w-[55px] h-[65px] "
                  alt="logo"
                />
              </div>
              <div className="ms-auto">
                <div
                  onClick={() => setIsOpen(false)}
                  className="text-white cursor-pointer"
                >
                  <ArrowLeft />
                </div>
              </div>
            </div>
            <nav className="mt-5 ">
              <div className=" py-1 fonts-500 rounded-full mt-10 ">
                <Link
                  to="/"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="sm:text-base text-sm leading-7">Home</p>
                </Link>
              </div>
              <div className=" py-1 fonts-500 rounded-full my-8">
                <Link
                  to="/faqs"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="sm:text-base text-sm leading-7">FAQs</p>
                </Link>
              </div>

              <div className=" py-1 fonts-500 rounded-full my-8">
                <Link
                  to="/#contact"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="sm:text-base text-sm  leading-7">Contact Us</p>
                </Link>
              </div>
              <div className=" py-1 fonts-500 rounded-full my-8">
                <Link
                  to="/policy"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="sm:text-base text-sm  leading-7">
                    Privacy Policy
                  </p>
                </Link>
              </div>
              <div className=" py-1 fonts-500 rounded-full my-8">
                <Link
                  to="/termsofuse"
                  onClick={() => setIsOpen(false)}
                  className="flex items-center whitespace-nowrap"
                >
                  <p className="sm:text-base text-sm  leading-7">
                    Terms Of Use
                  </p>
                </Link>
              </div>
              <div className=" py-1 fonts-500 rounded-full my-8">
                <button
                  onClick={handleregister}
                  className="flex items-center whitespace-nowrap "
                >
                  <p className="sm:text-base text-xs leading-7 sm:px-5 px-3  sm:py-2 py-1 font-medium bg-white text-black rounded-xl">
                    Sign Up as a Professional
                  </p>
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="relative z-50 "
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-[2px]" />

        <div className="fixed inset-0 flex w-screen lg:justify-end justify-center lg:items-start items-center lg:top-[80px] lg:pr-20">
          <DialogPanel
            className="max-w-2xl rounded-[30px] bg-black md:h-[700px] h-[500px] overflow-auto"
            style={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <div className="md:px-11 px-5 md:pb-10 pb-7 md:pt-2 pb-2 mt-2 ">
              <form
                onSubmit={formik.handleSubmit}
                className="caret-white text-white"
              >
                <div className="flex justify-end items-start mx-1 my-5">
                  <SlClose
                    className="text-2xl cursor-pointer"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div className="mb-6">
                  <input
                    id="fname"
                    type="text"
                    name="fname"
                    autoComplete="off"
                    placeholder="Full Name"
                    className="xl:w-[520px] md:w-[500px] sm:w-[400px] xs:w-[300px] w-full sm:h-11 h-10 px-5  rounded-xl  sm:text-base text-sm font-medium  outline-none border border-[#A5A5A5]  bg-[#404040]"
                    value={formik.values.fname}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.fname && formik.touched.fname && (
                    <p className="text-red-600  text-start ms-2 text-sm font-medium">
                      {formik.errors.fname}
                    </p>
                  )}
                </div>
                <div className=" mb-6">
                  <div className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-11 h-10">
                    <PhoneInput
                      country="in"
                      value={phoneData.number}
                      onChange={(phone, countryData) =>
                        handlePhoneChange(phone, countryData)
                      }
                      className="block w-full rounded-xl bg-white  sm:text-sm text-xs"
                      required
                    />
                  </div>
                  {formik.errors.phoneno && formik.touched.phoneno && (
                    <p className="text-red-600 text-start ms-2  text-sm font-medium">
                      {formik.errors.phoneno}
                    </p>
                  )}
                </div>

                <div className="mb-6">
                  <Listbox
                    value={formik.values.city}
                    onChange={(value) => {
                      formik.setFieldTouched("city", true);
                      formik.setFieldValue("city", value.name);
                    }}
                  >
                    <div className="relative mt-2">
                      <ListboxButton className="relative xl:w-[520px] md:w-[500px] sm:w-[400px] w-full cursor-default px-2 rounded-xl py-2 sm:text-base text-sm font-medium  border border-[#A5A5A5]  bg-[#404040] outline-none">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {formik.values.city || (
                              <p className="text-[#A5A5A5]">Select City</p>
                            )}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <TiArrowSortedDown
                            aria-hidden="true"
                            className="md:h-6 md:w-6 h-5 w-5 text-[#A5A5A5]"
                          />
                        </span>
                      </ListboxButton>

                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-56 xl:w-[520px] md:w-[500px] sm:w-[400px] w-full rounded-md bg-[#404040] py-1 text-base focus:outline-none  sm:text-sm dropdown border border-[#A5A5A5]"
                      >
                        {city.map((city) => (
                          <ListboxOption
                            key={city.id}
                            value={city}
                            className="group relative cursor-default select-none md:py-1.5 py-1 pl-3 pr-9 text-white data-[focus]:bg-[#6b6b6b] data-[focus]:text-white "
                          >
                            <div className="flex items-center">
                              <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold sm:text-base text-sm">
                                {city.name}
                              </span>
                            </div>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                    {formik.errors.city && formik.touched.city && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.city}
                      </p>
                    )}
                  </Listbox>
                </div>
                <div className="mb-6">
                  <Listbox
                    value={formik.values.profession}
                    onChange={(value) => {
                      formik.setFieldTouched("profession", true);
                      formik.setFieldValue("profession", value.name);
                    }}
                  >
                    <div className="relative mt-2">
                      <ListboxButton className="relative xl:w-[520px] md:w-[500px] sm:w-[400px] w-full cursor-default px-2 rounded-xl py-2 sm:text-base text-sm font-medium  border border-[#A5A5A5]  bg-[#404040] outline-none">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {formik.values.profession || (
                              <p className="text-[#A5A5A5]">Your Profession</p>
                            )}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <TiArrowSortedDown
                            aria-hidden="true"
                            className="md:h-6 md:w-6 h-5 w-5 text-[#A5A5A5]"
                          />
                        </span>
                      </ListboxButton>

                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-56 xl:w-[520px] md:w-[500px] sm:w-[400px] w-full rounded-md bg-[#404040] py-1 text-base focus:outline-none  sm:text-sm dropdown border border-[#A5A5A5]"
                      >
                        {profession.map((profession) => (
                          <ListboxOption
                            key={profession.id}
                            value={profession}
                            className="group relative cursor-default select-none md:py-1.5 py-1 pl-3 pr-9 text-white data-[focus]:bg-[#6b6b6b] data-[focus]:text-white "
                          >
                            <div className="flex items-center">
                              <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold sm:text-base text-sm">
                                {profession.name}
                              </span>
                            </div>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                    {formik.errors.profession && formik.touched.profession && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.profession}
                      </p>
                    )}
                  </Listbox>
                </div>
                <div className="mb-6">
                  <Listbox
                    value={formik.values.experience}
                    onChange={(value) => {
                      formik.setFieldTouched("experience", true);
                      formik.setFieldValue("experience", value.name);
                    }}
                  >
                    <div className="relative mt-2">
                      <ListboxButton className="relative xl:w-[520px] md:w-[500px] sm:w-[400px] w-full cursor-default px-2 rounded-xl py-2 sm:text-base text-sm font-medium  border border-[#A5A5A5]  bg-[#404040] outline-none">
                        <span className="flex items-center">
                          <span className="ml-3 block truncate">
                            {formik.values.experience || (
                              <p className="text-[#A5A5A5]">Total Experience</p>
                            )}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <TiArrowSortedDown
                            aria-hidden="true"
                            className="md:h-6 md:w-6 h-5 w-5 text-[#A5A5A5]"
                          />
                        </span>
                      </ListboxButton>

                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-56 xl:w-[520px] md:w-[500px] sm:w-[400px] w-full rounded-md bg-[#404040] py-1 text-base focus:outline-none  sm:text-sm dropdown border border-[#A5A5A5]"
                      >
                        {experience.map((experience) => (
                          <ListboxOption
                            key={experience.id}
                            value={experience}
                            className="group relative cursor-default select-none md:py-1.5 py-1 pl-3 pr-9 text-white data-[focus]:bg-[#6b6b6b] data-[focus]:text-white "
                          >
                            <div className="flex items-center">
                              <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold sm:text-base text-sm">
                                {experience.name}
                              </span>
                            </div>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                    {formik.errors.experience && formik.touched.experience && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.experience}
                      </p>
                    )}
                  </Listbox>
                </div>
                <div className="mb-6">
                  <Listbox
                    value={formik.values.gender}
                    onChange={(value) => {
                      formik.setFieldTouched("gender", true);
                      formik.setFieldValue("gender", value.name);
                    }}
                  >
                    <div className="relative mt-2">
                      <ListboxButton className="relative xl:w-[520px] md:w-[500px] sm:w-[400px] w-full cursor-default px-2 rounded-xl py-2 sm:text-base text-sm font-medium  border border-[#A5A5A5]  bg-[#404040] outline-none">
                        <span className="flex items-start">
                          <span className="ml-3 block truncate">
                            {formik.values.gender || (
                              <p className="text-[#A5A5A5]">Select Gender</p>
                            )}
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <TiArrowSortedDown
                            aria-hidden="true"
                            className="md:h-6 md:w-6 h-5 w-5 text-[#A5A5A5]"
                          />
                        </span>
                      </ListboxButton>

                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-56 xl:w-[520px] md:w-[500px] sm:w-[400px] w-full rounded-md bg-[#404040] py-1 text-base border border-[#A5A5A5] focus:outline-none  sm:text-sm dropdown"
                      >
                        {gender.map((gender) => (
                          <ListboxOption
                            key={gender.id}
                            value={gender}
                            className="group relative cursor-default select-none md:py-1.5 py-1 pl-3 pr-9 text-white data-[focus]:bg-[#6b6b6b] data-[focus]:text-white"
                          >
                            <div className="flex items-center">
                              <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold  sm:text-base text-sm">
                                {gender.name}
                              </span>
                            </div>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                    {formik.errors.gender && formik.touched.gender && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.gender}
                      </p>
                    )}
                  </Listbox>
                </div>
                <div className="mb-6">
                  <textarea
                    id="message"
                    name="message"
                    rows="5"
                    autoComplete="off"
                    placeholder="Enter Message..."
                    className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full px-5 rounded-xl pt-3.5  sm:text-base text-sm font-medium  border border-[#A5A5A5]  bg-[#404040] outline-none"
                    value={formik.values.message}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.errors.message && formik.touched.message && (
                    <p className="text-red-600 text-start ms-2 text-sm font-medium">
                      {formik.errors.message}
                    </p>
                  )}
                </div>
                <div className="flex justify-center items-center">
                  <button
                    className="text-black bg-white font-medium text-base text-center rounded-2xl md:h-11 md:w-[300px] h-10 sm:w-52  w-48 mt-5 cursor-pointer"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                <div className="flex justify-center items-center">
                  <button
                    onClick={handleclose}
                    className="text-white bg-black border border-[#6F6F6F] font-medium text-base text-center rounded-2xl md:h-11 md:w-[300px] h-10 sm:w-52  w-48 mt-6 cursor-pointer"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
export default Header;
